import $axios from './request';
 
// account模块
export const homemune = (data={})=> {
    return $axios.get('/api/home',data);
}

// 联系我们、轮播图"type = ' '   所有轮播图与广告位广告、 "type = contact联系我们页面富文本
export const homebanners = (data)=> {
    return $axios.get('/api/home/banner',data);
}
export const customize = (data)=> {
    return $axios.get('api/customize/type',{params:data});
}
// 文章列表
export const articlelist = (data)=> {
    return $axios.get('/api/home/article',data);
}
// 文章详情
export const articledatail = (data)=> {
    return $axios.get('/api/home/'+data.id);
}
// 首页图标功能详情
export const featuredatail = (data)=> {
    return $axios.get('/api/labels/'+data);
}
// 查询汇率详情
export const pricedatail = (data)=> {
    return $axios.get('/api/tool/rate',{params:data});
}
// 交易明细
export const tradingdatail = (data)=> {
    return $axios.get('/api/tool/record',{params:data});
}
// 钱包信息
export const transactionatail = (data)=> {
    return $axios.get('/api/tool/wallet',{params:data});
}
// 文章点赞
export const textlove = (data)=> {
    return $axios.get('/api/home/like/'+data.id);
}
// 标签点赞
export const labellove = (data)=> {
    return $axios.get('/api/labels/edit/'+data.id);
}

export const category = (data)=> {
    return $axios.post('/api/home/banner',data);
}