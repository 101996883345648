<template>
  <div class="indexcontent">
    <!-- banner -->
    <!-- <div class="index_block_4 flex-col">
      <div class="index_group_20 flex-col">
        <el-carousel>
          <el-carousel-item v-for="pic in banner1">
            <a aria-label="item.link" :href="pic.url" target="_blank">
              <img loading="lazy" class="index_swiper-img" :src="pic.img" alt="玩命加载中..." />
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div> -->
    <!-- 第二块 -->
    <div class="block_box">
      <!-- 资讯 -->
      <div class="zixun_list hide-scrollbar">
        <div class="box_4 flex-row justify-between">
          <span class="text_20">最新资讯</span>
          <img class="image_3" src="../assets/imgs/right.png" />
        </div>

        <div class="zixun_item" v-for="item in articledata" :key="item.id">
          <router-link :to="'infodatail/' + item.id" class="box_5 flex-row">
            <div class="image-text_18 flex-row justify-between">
              <img class="zixun_image" :src="item.icon" loading="lazy" />
              <div class="zixun_con flex-col">
                <span class="zixun_title">{{ item.title }}</span>
                <span class="zixun_texts">{{ item.describes }}</span>
                <p class="zixun_dat">
                  <span class="zixun_dat_item">{{ item.createTime }}</span>
                  <img class="zixun_dat_item" style="width: 14px;height: 12px;" src="../assets/imgs/查看.webp" />
                  <span class="zixun_dat_item">{{ item.browse }}</span>
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <!-- 第二块轮播 -->
      <div class="image_9">
        <el-carousel indicator-position="none">
          <el-carousel-item v-for="item in banner2" :key="item.img">
            <a :href="item.url" target="_blank">
              <img loading="lazy" class="index_swiper-img" :src="item.img" alt="玩命加载中..." /></a>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 第三块内容 -->
    <div class="index_list">
      <div class="index_item" v-for="item in datalist" :key="item.id">
        <!-- 内容分类 -->
        <template v-if="item.menuName != '关于我们'">
          <div class="image-text_15 flex-row justify-between" :id="'id=' + item.id">
            <!-- <img class="label_18" loading="lazy" src="../assets/imgs/icon.png" /> -->
            <img class="label_18" loading="lazy" :src="item.icon" />
            <span class="text-group_15">{{ item.menuName }}</span>
          </div>

          <!-- 内容二级分类 -->
          <div v-if="item.children.length != 0" class="text_secondnav">
            <span class="seconda_text" :class="{ 'secondactive': isActive(item.id, child.id) }"
              @mouseover="setActive(item.id, child.id)" v-for="child in item.children" :key="child.menuName">{{ child.menuName }}</span>

            <!-- <span class="seconda_text">常用网址</span>
      <span class="seconda_text">效率工具</span>
      <span class="seconda_text">常用工具</span> -->
          </div>

          <!-- 内容 -->
          <div class="item_content flex-row" v-show="activeIndexes[item.id]">
            <router-link :to="'/tooldatail/' + thirdLayer.labelId" class="list_box"
              v-for="thirdLayer in getActiveChildren(item.id)" :key="thirdLayer.labelId">
              <div class="inlist_item">
                <img class="image_6" loading="lazy" :src="thirdLayer.labelIcon" />
                <span class="item_title">{{ thirdLayer.labelName }}</span>
              </div>
              <p class="item_dec">{{ thirdLayer.describes }}</p>
            </router-link>
            <span style="display: inline-block; width: 300px;height: 0;"></span>
            <span style="display: inline-block;width: 300px;height: 0;"></span>
            <span style="display: inline-block;width: 300px;height: 0;"></span>
            <span style="display: inline-block;width: 300px;height: 0;"></span>
          </div>
        </template>
      </div>
    </div>


  </div>
</template>
<script>
import { homemune, homebanners, articlelist, } from "@/api/index";
export default {
  props: ['childid'],
  data() {
    return {
      constants: {},
      banner1: [],
      banner2: [],
      articledata: [],
      datalist: [],
      activeIndexes: {},
    };
  },
  mounted() {
  },
  created() {
    this.getData()
    // console.log('收到了',this.childid);
  },
  watch: {
    childid(newVal) {
      // console.log('变化了',newVal,oldVal);
      this.setActive(newVal[0], newVal[1])
    }
  },
  methods: {
    isActive(firstId, secondId) {
      return this.activeIndexes[firstId] === secondId;
    },
    setActive(firstId, secondId) {
      this.$set(this.activeIndexes, firstId, secondId);
    },
    getActiveChildren(firstId) {
      const firstLayer = this.datalist.find((item) => item.id === firstId);
      if (!firstLayer) return [];
      // console.log('第一层',firstLayer);
      const secondLayer = firstLayer.children.find((item) => item.id === this.activeIndexes[firstId]);
      // console.log('第二层',secondLayer);
      var ddre = secondLayer ? secondLayer.labelsList : [];
      if (!ddre) return {};
      // console.log('第三层',ddre);
      return ddre
    },
    getData() {
      //列表
      homemune().then(res => {
        this.datalist = res.data;
        console.log('列表', res.data)
        this.datalist.forEach((firstLayer) => {
          if (firstLayer.children && firstLayer.children.length > 0) {
            this.$set(this.activeIndexes, firstLayer.id, firstLayer.children[0].id);
          }
        });
      });
      // banner图详情
      homebanners({ type: '' }).then(res => {
        // console.log('轮播图', res);
        this.banner1 = JSON.parse(res.data['carousel1']['context']);
        this.banner2 = JSON.parse(res.data['carousel2']['context']);
        // console.log('轮播图1', this.banner1);
        // console.log('轮播图2', this.banner2);
        this.munelist = res.data;
      });
      // 文章列表
      articlelist().then(res => {
        this.articledata = res.data
        // console.log('文章',res.data);
      })

    },
  },
};
</script>
<style scoped>
@import url('./indexs.css');
</style>
