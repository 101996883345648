<template>
  <div class="page flex-col" id="lazyload">
    <div class="section_1 flex-col">
      <div class="block_1 flex-col">
        <div class="group_1">

          <!-- 头部导航栏 -->
          <div class="box_6">
            <div class="flex-row align-center" style="height: 100%;">
              <div class="logo_img-box" style="display: flex;">
                <img class="logo_img" src="../assets/logo.png" />
                <div class="logo-text">出海帮工具网</div>
              </div>

              <div class="headnav">
                <router-link to="/" :class="headType == '首页' ? 'group_8 flex-col justify-between' : 'group_8_active'">
                  <span @click="headNav('首页')" :class="headType == '首页' ? 'text_10' : 'text_11'">首页</span>
                  <!-- <div v-if="headType == '首页'" class="box_7 flex-col"></div> -->
                </router-link>
                <router-link to="/info"
                  :class="headType == '出海帮' ? 'group_8 flex-col justify-between' : 'group_8_active'">
                  <span @click="headNav('出海帮')" :class="headType == '出海帮' ? 'text_10' : 'text_11'">出海帮</span>
                  <!-- <div v-if="headType == '出海帮'" class="box_7 flex-col"></div> -->
                </router-link>

                <el-dropdown style="height: 100%;display: flex;justify-content: center;align-items: center;">
                  <div :class="headType == '工具一' || headType == '工具二' ? 'group_8 flex-row' : 'group_8_active'">
                    <span :class="headType == '工具一' || headType == '工具二' ? 'text_10' : 'text_11'">USDT小工具</span>
                    <img v-if="headType == '工具一' || headType == '工具二'" class="thumbnail_19"
                      style="width: 22px;height: 22px;" src="../assets/imgs/Fill2.png" />
                    <img v-else class="thumbnail_19" style="width: 10px;height: 18px;margin-left: 17px;"
                      src="../assets/imgs/Fill.webp" />
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="headNav('工具一')" icon="el-icon-suitcase">USDT交易记录查询</el-dropdown-item>
                    <el-dropdown-item @click.native="headNav('工具二')"
                      icon="el-icon-suitcase">USDT人民币汇率查询</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>

            <div class="group_9 flex-col justify-between">
              <span class="text_13">USDT实时汇率</span>
              <div class="text-wrapper_5">
                <span class="text_14">今日汇率</span>
                <span class="text_15">：</span>
                <span class="text_16">{{ money }}</span>
              </div>
            </div>
          </div>
          <!-- 主体 -->
          <div class="main">
            <!-- 侧边栏 -->
            <div class="box_1 hide-scrollbar flex-col">
              <div class="asidenav_item" v-for="item in munelist" :key="item.id">
                <!-- 一级菜单 -->
                <div
                  :class="asideType == item.id ? 'home_section_6 flex-row align-center' : 'section_2 flex-row align-center'"
                  @click="changeasidenav(item.id, item.children.length != 0 ? item.children[0].id : null, item.menuName)">
                  <img v-if="asideType == item.id" class="thumbnail_4" loading="lazy" :src="item.clickIcon" />
                  <img v-else class="thumbnail_4" :src="item.icon" loading="lazy">
                  <span :class="asideType == item.id ? 'text_4' : 'text_1'">{{ item.menuName }}</span>
                  <img v-if="asideType == item.id" class="home_thumbnail_5" src="../assets/imgs/open.webp" />
                  <img v-else class="home_thumbnail_1" src="../assets/imgs/shotdown.webp" />
                </div>
                <!-- 二级菜单 -->
                <div v-for="vid in item.children" :key="vid.id" class="second_box flex-row">
                  <template v-if="asideType == item.id && item.menuName != '关于我们'">
                    <div class="section_7 flex-col" @click="changeasidenav(item.id, vid.id)">
                      <div :class="asidesecType == vid.id ? 'group_4 flex-row' : 'text-wrapper_1 flex-row'">
                        <div :class="asidesecType == vid.id ? 'block_2 flex-col' : 'block_2_0 flex-col'"></div>
                        <span :class="asidesecType == vid.id ? 'text_7' : 'text_5'">{{ vid.menuName }}</span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <!-- 联系我们 -->
              <!-- <div class="contact">
                <div :class="asideType == 'p' ? 'home_section_6 flex-row align-center' : 'contactus flex-row align-center'"
                  @click="changeasidenav('p','contact')">
                  <img v-if="asideType == 'p'" class="thumbnail_4" 
                    src="../assets/imgs/call2.webp" />
                  <img v-else class="thumbnail_4"
                    src="../assets/imgs/call.png"
                    alt="">
                  <span :class="asideType == 'p' ? 'text_4' : 'text_1'">联系我们</span>
                  <img v-if="asideType == 'p'" class="home_thumbnail_5" 
                    src="../assets/imgs/open.webp" />
                  <img v-else class="home_thumbnail_1"  src="../assets/imgs/shotdown.webp" />
                </div>
              </div> -->

              <!-- 联系我们 -->
              <div class="contact">
                <div
                  :class="asideType == 'p' ? 'home_section_6 flex-row align-center' : 'contactus flex-row align-center'"
                  @click="changeasidenav('p', 'contact', '关于我们')">
                  <img v-if="asideType == 'p'" class="thumbnail_4" :src="munelist2[0].clickIcon" />
                  <img v-if="asideType != 'p'" class="thumbnail_4" :src="munelist2[0].icon" alt="">
                  <span :class="asideType == 'p' ? 'text_4' : 'text_1'">{{ munelist2[0].menuName }}</span>
                  <img v-if="asideType == 'p'" class="home_thumbnail_5" src="../assets/imgs/open.webp" />
                  <img v-else class="home_thumbnail_1" src="../assets/imgs/shotdown.webp" />
                </div>
              </div>
            </div>
            <!-- 内容区域 -->
            <div class="content hide-scrollbar" ref="homecontent">
              <router-view :childid="propId"></router-view>
            </div>

          </div>
          <!-- 全局联系客服 -->
          <!-- <router-link to="/customer" class="flatcosum el-icon-phone-outline">
          </router-link> -->
          <!-- 全局联系我们  -->
          <div class="contact-box" draggable="true" :style="`elLeft:${elLeft}px;top:${elTop}px`"
            @dragstart="dragstart($event)" @dragend="dragend($event)">
            <div class="contact1">
              <div class="contact-title1">{{ association.description.text0 }}</div>
              <div class="contact-img">
                <img :src="association.images.item1.src" alt="暂无图片" srcset="">
              </div>
              <div class="contact-title2">{{ association.description.text1 }}</div>
              <div class="contact-title3">
                <div>
                  {{ association.description.text2 }}
                </div>
              </div>
            </div>
          </div>
          <!-- 返回顶部 -->
          <div class="backtop el-icon-upload2" v-if="showButton" @click="scrollTop"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { homemune, pricedatail, customize } from "@/api/index";
export default {
  data() {
    return {
      showButton: false,
      headType: '首页',
      asidesecType: null,
      asideType: null,
      money: null,
      constants: {},
      munelist: [],//侧边栏菜单
      propId: [],
      association: {
        images: {
          item1: { src: '' }
        },
        description: {
          text0: '',
          text1: '',
          text2: ""
        }
      },
      munelist2: [],
      elLeft: "",
      elTop: "",
      //     right: 20px;
      // top: 30%;
      initWidth: 0, // 父元素的宽-自适应值
      initHeight: 0, // 父元素的高-自适应值
      startclientX: 0, // 元素拖拽前距离浏览器的X轴位置
      startclientY: 0, //元素拖拽前距离浏览器的Y轴位置
    };
  },
  created() {
    this.getMune()
    // console.log(this.$route);
    this.getData();
  },
  mounted() {

    var magantop = this.$refs.homecontent;
    magantop.addEventListener('scroll', () => {
      this.showButton = magantop.scrollTop > 100;  // 当滚动超过100px时显示按钮
      // console.log(123);
      // console.log(magantop.scrollTop);
      // window.scrollTo({ top: 0, behavior: 'smooth' });  // 平滑滚动到顶部
    });
  },
  methods: {
    dragstart(e) {
      this.startclientX = e.clientX; // 记录拖拽元素初始位置
      this.startclientY = e.clientY;

    },
    // 拖拽完成事件
    dragend(e) {
      console.log(e);
      this.elLeft = e.clientX; // 实现拖拽元素随偏移量移动
      this.elTop = e.clientY;
    },
    getData(type = '二维码') {
      var data = {
        type
      }
      customize(data).then(res => {
        if (res.code == 200) {
          if (res.data.length > 0) {
            this.association = res.data[0]
          }


        }

      });
    },
    // 获取菜单
    getMune() {
      homemune().then(res => {
        // console.log('菜单', res);
        var list1 = [];
        var list2 = [];
        res.data.forEach(element => {
          if (element.menuName == '关于我们') {
            list2.push(element)
          } else {
            list1.push(element)
          }
        });
        this.munelist = list1;
        this.munelist2 = list2;
        console.log(' this.munelist', this.munelist)
        console.log(' this.munelist2', this.munelist2)
      });
      var data = {
        num: 1,
        commission: 0,
        country: 'CNY',
      }
      pricedatail(data).then(res => {
        // console.log('汇率', res);
        this.money = res.data.rate;
      });
    },
    // 切换头部菜单
    headNav(type) {
      // console.log(type)
      this.headType = type
      if (type == '工具一' && this.$route.path != '/toolone') {
        this.$router.push('/toolone')
      } else if (type == '工具二' && this.$route.path != '/tooltwo') {
        this.$router.push('/tooltwo')
      }
    },
    // 侧边栏菜单
    changeasidenav(type, childid, changeasidenav = '其他',) {
      this.headType = '首页'
      // console.log(childid);
      this.propId = [type, childid]
      this.asideType = type
      this.asidesecType = childid
      if (changeasidenav == '关于我们') {
        console.log("222222222")


        this.$nextTick(() => {
          if (this.$route.path != '/customer') {
            this.$router.push({ path: '/customer' })
          }
        });
      } else {
        if (this.$route.path == '/index') {
          const element = document.getElementById('id=' + type);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        } else {
          this.$router.push({ path: '/index' }).then(() => {
            // 确保跳转完成后执行滚动
            this.$nextTick(() => {
              const element = document.getElementById('id=' + type);
              // console.log('进来了',element);
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              } else {
                // console.log('还没加载好进来了',element);
              }
            });
          });
        }
      }


    },
    checkScroll() {

      this.showButton = window.scrollY > 100;  // 当滚动超过100px时显示按钮
    },
    scrollTop() {
      const div = this.$refs.homecontent;
      div.scrollTop = 0; // 滚动到顶部（无平滑滚动）
    }
    // getsecondnav(type){
    //   this.asidesecType = type
    // }
  },
  beforeDestroy() {
    // 移除滚动事件监听器
    window.removeEventListener('scroll', this.checkScroll);
  }
}
</script>
<style scoped>
@import url('./home.css');
</style>
