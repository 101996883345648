<template>
  <div class="toolone" v-loading="loading">
    <el-form label-width="80px" ref="form" :rules="rules" style="padding: 0 26% 50px 26%;" :model="form">
      <div class="toolone_title">
        <span class="toolone_text">USDT交易记录查询</span>
      </div>
      <el-form-item label="货币类型">
        <el-select v-model="form.type" placeholder="请选择货币类型" clearable>
          <el-option label="ERC20_USDT" value="ERC20_USDT" />
          <el-option label="TRC20_USDT" value="TRC20_USDT" />
        </el-select>
      </el-form-item>
      <el-form-item label="钱包地址">
        <el-input type="textarea" style="height: 160px;" v-model="form.address" maxlength="255"
          show-word-limit></el-input>
      </el-form-item>
      <el-form-item style="text-align: right;">
        <el-button type="primary" style="height: 30px;line-height: 0px;border-color:#21ac34;background-color:#21ac34;" @click="handleQuery">查询钱包信息</el-button>
      </el-form-item>
    </el-form>
    <!-- 钱包信息 -->
    <div v-if="baginfokey == true" class="tool_box">
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix justify-start">
          <span>钱包信息</span>
          <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
        </div>
        <div class="text item">
          <table style="width: 100%;">
            <tr style="width: 100%;">
              <td class="td_right">是否是出海帮官方地址：否</td>

              <td class="td_right">是否是出海帮官方地址：否</td>

            </tr>
            <tr>
              <td class="td_right">类型：{{ billbag.type }}</td>
              <td class="td_right">余额：{{ billbag.balance }}</td>
            </tr>
            <tr>
              <td class="td_right">总收入：{{ billbag.income }}</td>
              <td class="td_right">总转出：{{ billbag.expend }}</td>
            </tr>
            <tr>
              <td class="td_right">交易笔数：{{ billbag.total }}</td>
              <td class="td_right">首次交易时间：{{ billbag.firstTime }}</td>
            </tr>
            <tr>
              <td class="td_right">最后交易时间：{{ billbag.lastTime }}</td>
              <td class="td_right">最大交易额：{{ billbag.maxValue }}</td>
            </tr>
            <tr>
              <td class="td_right">转入/转出对手数：{{ billbag.in }}/{{ billbag.out }}</td>
              <td class="td_right">最近一个月交易笔数：{{ billbag.monthCount }}</td>
            </tr>
          </table>
        </div>
      </el-card>
    </div>
    <!-- 近期与出海帮官方交易 -->
    <div v-if="baginfokey == true" class="tool_box">
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix justify-start">
          <span>近期与出海帮官方交易</span>
          <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
        </div>
        <div class="text item">
          <table style="width: 100%;">
            <tr style="width: 100%;">
              <td class="td_right">转出：{{ billbag.in }}</td>

              <td class="td_right">转入：{{ billbag.out }}</td>

            </tr>
            <tr>
              <td class="td_right">被投诉次数：0</td>
              <td class="td_right">被查询次数：{{ billbag.query }}</td>
            </tr>
          </table>
        </div>
      </el-card>
    </div>
    <!-- 查询交易记录 -->
    <div v-if="baginfokey == true">
      <el-button style="border-color:#21ac34;background-color:#21ac34;" v-if="billkey == false" type="info" @click="getList" plain>查询交易记录</el-button>
    </div>
    <!-- 交易明细 -->
    <div v-if="billkey == true" class="tool_box">
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix justify-between">
          <p>交易明细</p>
          <el-pagination  @size-change="getList" @current-change="getList" :current-page.sync="pageNum"
            :page-size.sync="pageSize" :page-sizes="[10, 20, 30, 40, 50]" :pager-count="5" layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
          <!-- <pagination
      :total="total"
      :page.sync="pageNum"
      :limit.sync="pageSize"
      @pagination="getList"
    /> -->
          <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
        </div>
        <div class="text item">
          <el-table v-loading="loading" :data="billList">
            <el-table-column label="交易哈希" align="center" prop="transactionHash" />
            <el-table-column label="区块高度" align="center" prop="block" />
            <el-table-column label="时间" align="center" prop="timestamp" />
            <el-table-column label="From" align="center" prop="transferFromAddress" />
            <el-table-column label="To" align="center" prop="transferToAddress" />
            <el-table-column label="交易总额" align="center" prop="amount" />
            <!-- <el-table-column label="用户账号" align="center" prop="userName">
      <template slot-scope="scope">
              <span v-if="scope.row.userName==null">---</span>
              <span v-else>{{ scope.row.userName }}</span>
         </template>
        </el-table-column> -->

          </el-table>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import { transactionatail, tradingdatail } from "@/api/index";
export default {
  data() {
    return {
      baginfokey: false,
      billkey: false,
      loading: false,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      resultkey: false,
      billbag: [],
      billList: [],
      form: {
        address: null,
        type: null,
      },
      options: [],
      rules: {
        address: [{ required: true, message: "钱包地址不能为空", trigger: "blur" }],
        type: [{ required: true, message: "货币类型不能为空", trigger: "blur" }],
      }
    };
  },
  methods: {
    handleQuery() {
      this.loading = true
      this.$refs["form"].validate(valid => {
        if (valid) {
          transactionatail(this.form).then(res => {
            this.loading = false
            this.baginfokey = true
            this.billkey = false
            // console.log('交易记录', res);
            this.billbag = res.data;
          });
        }
      })
    },
    // 表单重置
    reset() {
      this.form = {
        type: null,
        address: null,
      };
      this.resetForm("form");
    },
    // 查询账单列表
    getList() {
      // console.log(this.pageNum);
      // return 
      this.loading = true
      this.billkey = true
      var data = {
        address: this.form.address,
        page: this.pageNum,
        size: this.pageSize,
      }
      tradingdatail(data).then(res => {
        this.loading = false
        // console.log('交易明细', res);
        this.total = res.data.total
        this.billList = res.data.recordList;
      });
    }
  },
};
</script>
<style scoped>
@import url('./toolone.css');
</style>
<!-- <style src="./toolone.css" /> -->