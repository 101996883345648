<template>
  <div class="tooltwo">
    <el-form label-width="80px" ref="form" :rules="rules" style="padding: 0 200px 50px 200px;" :model="form">
      <div class="tooltwo_title">
        <span class="tooltwo_text">USDT人民币汇率查询</span>
      </div>
      <el-form-item style="text-align: left;">
        <p>
        <!-- <span style="font-size: 18px;font-weight: 800;">货币兑换：</span> -->
        <span v-if="form.country=='USD'">1 USDT={{moneyresult.rate}} 美币</span>
        <span v-else-if="form.country=='MYR'">1 USDT={{moneyresult.rate}} 马币</span>
        <span v-else-if="form.country=='SGD'">1 USDT={{moneyresult.rate}} 新币</span>
        <span v-else>1 USDT={{moneyresult.rate}} 人民币</span>
        <span style="margin-left: 20px;color: #21AC34;margin-right: 15px;" @click="getList()">获取最新单价</span>
        <span>更新时间：{{updatatime}}</span>
      </p>
      </el-form-item>
      
      <el-form-item label="类型">
        <el-select v-model="form.country" @change="getList()" placeholder="请选择转换类型" clearable>
          <el-option label="USDT转人民币" value="CNY" />
          <el-option label="USDT转美币" value="USD" />
          <el-option label="USDT转马币" value="MYR" />
          <el-option label="USDT转新币" value="SGD" />
        </el-select>
      </el-form-item>
      <el-form-item label="数量">
        <div class="num_box">
          <button class="button_left numbutton" @click="reduce('num')">-</button>
          <input type="number" v-model="form.num" class="numinput" placeholder="请输入转换数量">
          <button class="button_right numbutton" @click="add('num')">+</button>
        </div>
      </el-form-item>
      <el-form-item label="手续费">
        <el-select v-model="form.commission1" @change="choesescale" placeholder="请选择手续费" clearable>
          <el-option label="0%" value="0" />
          <el-option label="1%" value="1" />
          <el-option label="1.5%" value="1.5" />
          <el-option label="自定义" value="none" />
        </el-select>
      </el-form-item>
      <el-form-item label="" v-if="showopen">
        <div class="num_box2">
          <button class="button_left numbutton2" @click="reduce('commission')">-</button>
          <input type="number" v-model="form.commission2" class="numinput2">
          <button class="button_right numbutton2" @click="add('commission')">+</button>
          <span>%</span>
        </div>
      </el-form-item>
      
      <el-form-item style="text-align: right;">
        <el-button style="border-color:#21ac34;background-color:#21ac34;height: 30px;line-height: 0px;" type="primary" @click="getList('all')">转换</el-button>
      </el-form-item>
    </el-form>
    <!-- 查询结果 -->
    <div class="result_box">
      <p v-show="showresult==true">
        <span style="font-size: 18px;font-weight: 800;">货币兑换：</span>
        <span v-if="form.country=='CNY'">{{form.num}} USDT={{moneyresult.total}} 人民币</span>
        <span v-if="form.country=='USD'">{{form.num}} USDT={{moneyresult.total}} 美币</span>
        <span v-if="form.country=='MYR'">{{form.num}} USDT={{moneyresult.total}} 马币</span>
        <span v-if="form.country=='SGD'">{{form.num}} USDT={{moneyresult.total}} 新币</span>
      </p>
      <!-- <el-button type="primary" style="height: 30px;line-height: 0px;" @click="handleQuery">复制结果</el-button> -->
    </div>
    <el-button v-show="showresult==true" type="primary" style="height: 30px;line-height: 0px;margin-bottom: 50px;" @click="coptbutton()">复制结果</el-button>
    <!-- 弹窗复制信息确认 -->
    <el-dialog
  title="提示"
  :visible.sync="open"
  width="30%">
  <div class="flex-col" id="copeytext">
    <p>U汇率是：{{moneyresult.rate}}，更新时间：{{updatatime}}</p>
    <p v-if="form.country=='CNY'">1 USDT={{moneyresult.rate}} 人民币</p>
        <p v-if="form.country=='USD'">1 USDT={{moneyresult.rate}} 美币</p>
        <p v-if="form.country=='MYR'">1 USDT={{moneyresult.rate}} 马币</p>
        <p v-if="form.country=='SGD'">1 USDT={{moneyresult.rate}} 新币</p>
    <p>手续费：{{form.commission}}%</p>
    <p v-if="form.country=='CNY'">{{form.num}} USDT={{moneyresult.total}} 人民币</p>
        <p v-if="form.country=='USD'">{{form.num}} USDT={{moneyresult.total}} 美币</p>
        <p v-if="form.country=='MYR'">{{form.num}} USDT={{moneyresult.total}} 马币</p>
        <p v-if="form.country=='SGD'">{{form.num}} USDT={{moneyresult.total}} 新币</p>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="open = false">取 消</el-button>
    <el-button type="primary" @click="copyText()">确 定</el-button>
  </span>
</el-dialog>
 </div>
</template>
<script>
 import { pricedatail } from "@/api/index";
export default {
  data() {
    return {
      showopen:false,
      showresult:false,
      open:false,
      moneyresult:[],
      updatatime:null,
      form: {
        num:0,
        commission: null,
        commission1: null,
        commission2: null,
        country: null,
      },
      rules:{
        num:[{ required: true, message: "数量不能为空", trigger: "blur" }],
        commission:[{ required: true, message: "转换比例不能为空", trigger: "blur" }],
        country:[{ required: true, message: "转换类型不能为空", trigger: "blur" }],
      }
    };
  },
  created(){
    this.getList()
  },
  methods: {
    // 查询汇率
    getList(type){
      if(type==undefined){
        var dat={
          num:0,commission: 0,country:this.form.country,
        }
        if(this.form.country==null){
          dat.country='CNY'
        }
        pricedatail(dat).then(res => {
          this.updatatime=this.formatDate(new Date());
        this.moneyresult = res.data;
      });
      }else if(type=='all'){
        this.$refs["form"].validate(valid => {
        if (valid) {
      if(this.form.commission1=='none'){
        this.form.commission=this.form.commission2
      }else{
        this.form.commission=this.form.commission1
      }
      // console.log(this.form);
      pricedatail(this.form).then(res => {
        this.showresult=true
        // console.log('汇率', res);
        this.moneyresult = res.data;
      });
    }
  })
      }
      
    },
     // 表单重置
     reset() {
      this.form = {
        num:0,
        commission: null,
        commission1: null,
        commission2: null,
        country: null,
      };
      this.resetForm("form");
    },
    // 数量减少
    reduce(type){
      if(type=='num'){
        this.form.num = Math.max(0, this.form.num - 1);
      }else{
        this.form.commission2 = Math.max(0, this.form.commission2 - 1);
      }
     
    },
    // 数量增加
    add(type){
      if(type=='num'){
        this.form.num = this.form.num*1+1;
      }else{
        this.form.commission2 = this.form.commission2*1+1;
      }
    },

    // 选择比例
    choesescale(value){
      if(value=='none'){
        this.showopen=true
      }else{
        this.commission=this.commission1
      }
    },
    formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  },
    coptbutton(){
      this.open = true
      this.updatatime=this.formatDate(new Date());
    },
    
     // 复制按钮
    copyText(){
      var copytext=document.querySelector('#copeytext').innerText
      navigator.clipboard.writeText(copytext).then(function() {
        // console.log('文本成功复制到剪贴板');
      }).catch(function(err) {
        console.log('无法复制文本: ', err);
      });
      this.open=false
    }
  },
};
</script>
<style scoped>
@import url('./tooltwo.css');
</style>
<!-- <style src="./tooltwo.css" /> -->