import Vue from 'vue'
import Router from 'vue-router'
import Home from '../components/Home'
import Index from '../components/indexs'
import Customer from '../components/customer'
import Info from '../components/info'
import Infodatail from '../components/infodatail'
import Toolone from '../components/toolone'
import Tooltwo from '../components/tooltwo'
import Tooldatail from '../components/tooldatail'
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      redirect: '/index',
       children: [
        {
          path: 'index',
          name: 'Index',
          component: Index,
        },
      {
        path: 'customer', 
        name: 'Customer',
        component: Customer,
      },
      {
        path: 'info',  
        name: 'Info',
        component: Info,
      },
      {
        path: 'infodatail/:id', 
        name: 'Infodatail',
        component: Infodatail,
      },
      {
        path: 'toolone',  
        name: 'Toolone',
        component: Toolone,
      },
      {
        path: 'tooltwo',  
        name: 'Tooltwo',
        component: Tooltwo,
      },
      {
        path: 'tooldatail/:id',  
        name: 'Tooldatail',
        component: Tooldatail,
      }
    ]
    }
  ],
  mode: 'hash'
})