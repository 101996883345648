<template>
  <div class="tooldatail">
    <div class="info_block flex-row justify-between">
              <!-- 文章 -->
              <div class="info_title flex-col">
                <div class="tooldatail_banner">
                  <div class="tool_imgbox">
                    <div class="tool_topbox">
                      <img class="tool_top_img" :src="infodataillist.labelIcon" alt="">
                      <p class="left_title">{{infodataillist.labelName}}</p>
                    </div>
                   <!-- lable标签 -->
                    <div class="tool_topbox2" v-if="infodataillist.type!='APP'">
                      <div class="topbox2_top">
                        <div class="topbox2_button">
                          <!-- <img src="../assets/imgs/bg1.png" alt="" class="bg1"> -->
                          <p class="link">{{infodataillist.menuName}}</p>
                          
                        </div>
                        <img src="../assets/imgs/方向向右-双@2x.png" style="margin: 0 10px;" alt="">
                        <div class="topbox2_button">
                          <!-- <img src="../assets/imgs/bg1.png" alt="" class="bg1"> -->
                          <p class="link">{{infodataillist.labelName}}</p>
                        </div>
                      </div>
                      <div class="topbox2_cent">
                        <span class="cent_title">{{infodataillist.labelName}}</span>
                        <el-tooltip :content="infodataillist.describes" placement="top" popper-class="tag_top" effect="light">
                          <span class="cent_dec">{{ infodataillist.describes }}</span>
                        </el-tooltip>
                      </div>
                      <div class="topbox2_buttom">
                        <div class="flex-row align-center">
                          <div class="topbox2_button">
                            <img src="../assets/imgs/bg1.png" alt="" class="bg1">
                          <a class="link" :href="infodataillist.labelUrl" style="color:#fff" target="_black">打开链接</a>
                        </div>
                        <!-- <img style="margin: 0 15px;width: 30px;height: 30px;" src="../assets/imgs/链接.png" alt=""> -->
                        </div>
                        <div class="flex-row align-center">
                           <!-- <p class="topbox2_link">链接成功</p> -->
                        <p class="app_box" style="font-size: 16px;" @click="givelike()">
                          <!-- <span class="el-icon-view" ></span> -->
                          <img style="height: 14px; width: 14px;" src="../assets/imgs/dianzan.png" alt="">
                          <span style="margin-left: 5px;">{{infodataillist.praise}}</span>
                        </p>
                        <!-- <img @click="givelike()" class="tool_button_ico" src="../assets/imgs/点赞.webp" alt="">
                        <p style="margin-right: 10px;">{{infodataillist.praise}}</p> -->
                        <p class="app_box" style="font-size: 16px;">
                          <span class="el-icon-view" ></span>
                          <span style="margin-left: 5px;">{{infodataillist.browse}}</span>
                        </p>
                        <!-- <img class="tool_button_ico" src="../assets/imgs/查看.webp" alt="">
                        <p>{{infodataillist.browse}}</p> -->
                        </div>
                       
                      </div>
                    </div>


                    <!-- APP -->
                    <div class="tool_topbox2" v-else>
                      <div class="topbox2_cent">
                        <span class="cent_title">{{infodataillist.labelName}}</span>
                        <el-tooltip :content="infodataillist.describes" placement="top" popper-class="tag_top" effect="light">
                          <span class="cent_dec">{{ infodataillist.describes }}</span>
                        </el-tooltip>
                        
                      </div>

                      <div class="app_type">
                        <p class="app_box">
                          <span class="el-icon-medal-1" ></span>
                          <span style="margin-left: 5px;">官方版</span>
                        </p>
                        <p class="app_box">
                          <span class="el-icon-s-check" ></span>
                          <span style="margin-left: 5px;">无广告</span>
                        </p>
                        <p class="app_box" @click="givelike()">
                          <!-- <span class="el-icon-view" ></span> -->
                          <img style="height: 14px; width: 14px;" src="../assets/imgs/dianzan.png" alt="">
                          <span style="margin-left: 5px;">{{infodataillist.praise}}</span>
                        </p>
                        <p class="app_box">
                          <span class="el-icon-view" ></span>
                          <span style="margin-left: 5px;">{{infodataillist.browse}}</span>
                        </p>
                      </div>

                      <div class="app_type">
                        <p>更新日期：{{infodataillist.createTime}}</p>
                        <p style="margin-left: 10px;">分类：{{infodataillist.menuName}}</p>
                        <p style="margin-left:10px;">大小：{{infodataillist.size}}MB</p>
                      </div>

                      <div class="topbox2_buttom ">
                        <div class="topbox2_button">
                          <a :href="infodataillist.labelUrl" target="_black">下载APP</a>
                        </div>
                        <!-- <p style="font-size: 12px;margin:0 5px;">大小：{{infodataillist.size}}MB</p> -->
                        <!-- <div class="topbox2_button" @click="givelike()" >
                          <img class="tool_button_ico" src="../assets/imgs/点赞.webp" alt="">
                          <p>{{infodataillist.praise}}</p>
                        </div> -->
                        
                      </div>
                    </div>
                  </div>
                </div>
                <p class="info_title_text">{{infodataillist.title}}</p>
                <!-- 点赞及时间 -->
                <!-- <div class="dec_title flex-row">
                  <div class="dec_box">
                    <img
                    class="dec_img"
                    referrerpolicy="no-referrer"
                    src="infodataillist.icon"
                  />
                  <img
                    class="dec_img"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/psf6esrbw792frfe35t1dfcfaevyl0i0qh71d204f26-359e-481d-a591-2b66931ff77b"
                  />
                  <p class="dec_text">{{infodataillist.createTime}}</p>
                  <img
                    class="dec_img"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/pskti8u5zpjzsr25afcsfrieakw7u7ant26eb32fe6a-2eed-4b7a-b69f-b165de877ce5"
                  />
                  <p class="dec_text">{{infodataillist.author}}</p>
                  </div>
                  <div class="dec_box">
                    <img
                    class="dec_img"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/psc94us4efu254s3ti2rrmv8d4slmuqp12j033a6283-dc87-4b43-a68f-61b46a8763f1"
                  />
                  <p class="dec_data" style="margin-right: 25px;">{{infodataillist.praise}}</p>
                  <img
                    class="dec_img"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/pshweenaaiogfdvgxuhciz7e5sw3l0tmg9s58dc69d5-8348-4764-b2f0-04416bccf793"
                  />
                  <p class="dec_data">{{infodataillist.browse}}</p>
                  </div>
                </div> -->
                <!-- 内容 -->
                <div class="info_content_box ql-editor" v-html="infodataillist.context"></div>
                <a :href="advimg?advimg.url:''" target="_black"  class="tool_adv">
                    <img class="tool_adv_img" loading="lazy" :src="advimg?advimg.img:''" alt="">
                </a>
              </div>
              <!-- 联系我们模块 -->
              <div class="adv_box">
                <router-link to="/customer" style="position: sticky;top: 0;width: 100%;" >
                  <img class="adv_img" :src="datailbanner.img" alt="">
                </router-link>
                
              </div>
            </div>
  </div>
    
</template>


  <script>
  import { featuredatail,homebanners,labellove } from "@/api/index";
  export default {
    data() {
      return {
        infodataillist: [],
        id:null,
        advimg:null,
        datailbanner:[],
        haslove:false
      };
    },
    created(){
      this.id=this.$route.params.id
      // console.log(this.id);
      this.gettagList();
    },
    methods: {
      gettagList(){
        // banner图详情
      homebanners({type:''}).then(res => {
        // console.log('轮播图', res);
        this.advimg =JSON.parse(res.data.advert7.context);
        this.datailbanner =JSON.parse(res.data.advert8.context);
        // console.log('gg', this.advimg);
        // console.log('轮播图2', this.banner2);
        this.munelist = res.data;
      });
      // 文章列表
      featuredatail(this.id).then(res => {
        this.infodataillist=res.data
        // console.log('文章',res.data);
      })
      },
       // 点赞
    givelike(){
      var data={
        id:this.id
      }
      if(this.haslove==false){
        labellove(data).then(res => {
        if(res.code==200){
          this.haslove=true
          this.$set(this.infodataillist,'praise',res.data.praise)
      //     featuredatail(this.id).then(res => {
      //   this.infodataillist=res.data
      //   // console.log('文章',res.data);
      // })
        }
        // console.log('点赞',res)
      });
      }else{
        this.$message({
          message: '您已经点过赞了',
          type: 'success'
        });
      }
      
    }
    },
  };
  </script>
  <style scoped>
  @import url(./tooldatail.css);
  /* @import url(quill/dist/quill.core.css); */
  </style>
  