<template>
  <div class="info">
    <div class="img_box">
        <div class="swiper_box flex-col">
        <el-carousel >
            <el-carousel-item v-for="pic in infobanner" :key="pic.img" >
                <img loading="lazy" class="info_swiper-img"
                  :src="pic.img" alt="玩命加载中..." />
            </el-carousel-item>
          </el-carousel>
      </div>

      <!-- <div class="imgs_list">
        <a :href="inforighttop.url" target="_black" class="imgs_item">
          <img style="width: 100%;height: 100%;" loading="lazy" :src="inforighttop.img" alt="">
        </a>
        <a :href="inforightbutton.url" target="_black" class="imgs_item">
          <img style="width: 100%;height: 100%;" loading="lazy" :src="inforightbutton.img" alt="">
        </a>
      </div> -->
    </div>
    <!-- 列表 -->
    <div class="empty" v-if="newssearchlist == undefined || newssearchlist.length == 0">
        <el-empty style="width: 100%;height: 100%;" description="这里空空如也，看看其他的吧！"></el-empty>
        <!-- <van-empty description="这里空空如也，看一下其他的吧！" /> -->
      </div>
      <div class="help_left" v-else>
        <router-link :to="'infodatail/'+item.id" class="index_group" v-for="item in newssearchlist" :key="item.id">
          <!-- <img src="../../static/images/textborder.webp" alt="" class="textbg"> -->
          <div class="infoindex_item" style=" ">
            <div class="help_group_img">
            <img style="width: 100%;height: 100%;" loading="lazy" :src="item.icon" :alt="item.title" />
          </div>
          
          <div class="group_text" >
            <span class="infotext_title" >{{ item.title }}</span>
            <span class="help_left_text" >{{ item.describes }}</span>
            <div class="icon_text">
                <p class="flex-row"> 
                    <img src="../assets/imgs/book.webp" loading="lazy" style="width: 13px;height: 16px;" alt="">
                    <span style="margin-left: 10px;">{{item.tag}}</span>
                </p>
                <p>{{ item.createTime }}</p>
            </div>
          </div>
          </div>
        </router-link>
      </div>
  </div>
</template>

<script>
import { homebanners,articlelist } from "@/api/index";
export default {
  name: 'info',
  components: {

  },
  data() {
    return {
        newssearchlist:[],
        infobanner:[],
        inforighttop:[],
        inforightbutton:[],
    }
  },
  computed: {

  },
  created() {
    this.getList()
  },
  methods: {
    getList(){
         // 文章列表
      articlelist().then(res => {
        this.newssearchlist=res.data
        // console.log('文章',res.data);
      })
       // banner图详情
       homebanners({type:''}).then(res => {
        // console.log('轮播图', res);
        this.infobanner =JSON.parse(res.data['carousel3']['context']);
        this.inforighttop=JSON.parse(res.data.advert9.context);
        this.inforightbutton=JSON.parse(res.data.advert10.context);
        // console.log('资讯轮播图', this.infobanner);
      });
    }
  },
}
</script>

<style scoped>
@import url('./info.css');

</style>