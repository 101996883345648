import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/style/comm.css';
import 'quill/dist/quill.core.css';
import router from './router'
import "swiper/css/swiper.min.css"
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
Vue.component('Swiper', Swiper);
Vue.component('SwiperSlide', SwiperSlide);

Vue.config.productionTip = false

Vue.use(ElementUI);
new Vue({
  el: '#app',
  router,
  render: h => h(App)
})

