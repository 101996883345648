<template>
    <div class="infodatail flex-col">
      <div id="articletop"></div>
            <div class="info_block flex-row justify-between">
              <!-- 文章 -->
              <div class="info_title flex-col">
                <p class="info_title_text">{{infodataillist.title}}</p>
                <!-- 点赞及时间 -->
                <div class="dec_title flex-row">
                  <div class="dec_box">
                    <img
                    class="dec_img"
                    style="margin-right: 15px;"
                    loading="lazy"
                    src="../assets/imgs/book.webp"
                  />
                  <img
                    class="dec_img"
                    loading="lazy"
                    src="../assets/imgs/clock.webp"
                  />
                  <p class="dec_text">{{infodataillist.createTime}}</p>
                  <img
                    class="dec_img"
                    loading="lazy"
                    src="../assets/imgs/user.webp"
                  />
                  <p class="dec_text">{{infodataillist.author}}</p>
                  </div>
                  <div class="dec_box">
                    <!-- 点赞 -->
                    <img
                    class="dec_img"
                    @click="givelike()"
                    loading="lazy"
                    src="../assets/imgs/点赞.webp"
                  />
                  <p class="dec_data" style="margin-right: 25px;">{{infodataillist.praise}}</p>
                  <img
                    class="dec_img"
                    loading="lazy"
                    src="../assets/imgs/查看.webp"
                  />
                  <p class="dec_data">{{infodataillist.browse}}</p>
                  </div>
                </div>
                <!-- 内容 -->
                <div class="info_content_box ql-editor"  v-html="infodataillist.context"></div>
               
              </div>
              <!-- 联系我们模块 -->
              <div class="info_adv_box">
                <router-link to="/customer"  >
                  <img class="adv_img" loading="lazy" :src="datailbanner?datailbanner.img:''" alt="">
                </router-link>
                
              </div>
            </div>
<!-- 底部版权模块 -->
            <div class="info_button">
              <div class="info_button_box">
                <span class="info_button_text">版权声明：</span>
                <span class="info_button_text2">{{infodataillist.author}}&nbsp;发表于{{infodataillist.createTime}}</span>
              </div>
              <div class="info_button_box">
                <span class="info_button_text">转载请注明：</span>
                <span class="info_button_text2">出海帮广告位招租</span>
              <span style="margin: 0 10px; display: inline-block; width:1px;height: 18px;border: 1px solid #21AC34;"></span>
               <span class="info_button_text2">全球社交流量导航</span>
              </div>
            </div>
           
            <!-- 翻页 -->
            <!-- <div class="page_box">
              <span class="page_text" @click="changpage(infodataillist.beforeArticle)">上一篇</span>
              <span class="page_text" @click="changpage(infodataillist.afterArticle)">下一篇</span>
            </div> -->
          </div>
</template>
<script>
import { articledatail,homebanners,textlove } from "@/api/index";
export default {
 data() {
   return {
    haslove:false,
     infodataillist: {},
     datailbanner:null,
     id:null
   };
 },
 created(){
  // console.log(this.$route.params.id);
  this.id=this.$route.params.id
  this.getdatail()
 },
 methods: {
  getdatail() {
    var data={
      id:this.id
    }
    articledatail(data).then(res => {
        // console.log('文章详情', res);
        this.infodataillist = res.data;
      });
      // banner图详情
      homebanners({type:''}).then(res => {
        // console.log('轮播图', res.data.advert8.context);
        this.datailbanner =JSON.parse(res.data.advert8.context);
        // console.log('资讯轮播图', this.datailbanner);
      });
    },
    // 上一篇、下一篇
    // changpage(id){
    //   console.log('idssss',id.length,id==null);
    //   if(id.length==0){
    //     console.log('空了');
    //     this.$message({
    //       message: '已经没有了，看看其他的吧',
    //       type: 'success'
    //     });
    //   }else{
    //     this.haslove=false
    //   this.id=id
    //   const element = document.querySelector('#articletop');
    //   if (element) {
    //     element.scrollIntoView({});
    //   }
    //   this.getdatail()
    //   }
      
    // },
    // 点赞
    givelike(){
      var data={
        id:this.id
      }
      if(this.haslove==false){
        textlove(data).then(res => {
        if(res.code==200){
          this.$set(this.infodataillist,'praise',res.data.praise)
          // console.log('点赞',res.data.praise)
          this.haslove=true
          // this.getdatail()
        }
        
      });
      }else{
        this.$message({
          message: '您已经点过赞了',
          type: 'success'
        });
      }
      
    }
 },
};
</script>
<style scoped>
  @import url(./infodatail.css);
  /* @import url(quill/dist/quill.core.css); */
  </style>