<template>
  <div class="customer">
    <div class="index_block_4 flex-col">
      <div class="index_group_20 flex-col">
        <el-carousel>
          <el-carousel-item v-for="item in banners" :key="item.url">
            <a aria-label="item.link" :href="item.url" target="_blank">
              <img loading="lazy" class="index_swiper-img" :src="item.img" alt="玩命加载中..." />
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="box">
      <el-tabs v-model="active" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in munelist" :key="item.menuName" :name="index + ''">
          <template v-if="index < 3">
            <span slot="label">{{ item.menuName }}</span>
            <div class="ele-wrap">
              <div class="ele" v-for="(item, index) in item.labelsList" :key="index">
                <div class="ele-item">
                  <!-- :href="item.labelUrl"   target="_blank" -->
                  <span v-if="item.labelUrl">
                    <img :src="item.labelIcon" alt="">
                  </span>
                </div>
                <div class="ele-font ">{{ item.describes }}</div>
              </div>
            </div>
          </template>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="box1">
      <div class="title">{{ munelist[3].menuName }}</div>
      <div class="ele-wrap">
        <div class="ele" v-for="(item, index) in munelist[3].labelsList" :key="index">
          <div class="ele-item">
            <span v-if="item.labelUrl">
              <img :src="item.labelIcon" alt="">
            </span>

          </div>
          <div class="ele-font ">{{ item.describes }}</div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="box2-title">官方社群</div>
      <div class="box swiper1" style="margin-left: 36px;margin-right: 36px;">
        <div v-for="(item, index) in association" :key="item.description.text0 + index">
          <div class="swiper1-box" :style="!item.customizeId ? 'border:none' : ''"
            @mouseenter="handleMouseEnter(1, index)" @mouseleave="handleMouseLeave(1, index)">
            <div class="swiper1-item" v-if="item.customizeId">
              <img :src="item.images.item0.src">
              <div class="text0">{{ item.description.text0 }}</div>
              <div class="text1" :style="item.show ? 'font-size:0' : ''">{{ item.description.text2 }}</div>
            </div>
            <div :class="item.show ? 'mask text2-box' : 'mask1 text2-box'" v-if="item.customizeId">
              <img :src="item.images.item1.src">
              <div class="text2">{{ item.description.text0 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="box2-title">跨境资料</div>
      <div class="box swiper1" style="margin-left: 36px;margin-right: 36px;">
        <div v-for="(item, index) in association1" :key="item.description.text0 + index">
          <div :style="!item.customizeId ? 'border:none' : ''" class="swiper1-box"
            @mouseenter="handleMouseEnter(2, index)" @mouseleave="handleMouseLeave(2, index)">
            <div class="swiper1-item" v-if="item.customizeId">
              <img :src="item.images.item0.src">
              <div class="text0">{{ item.description.text0 }}</div>
              <div class="text1" :style="item.show ? 'font-size:0' : ''">{{ item.description.text2 }}</div>
            </div>
            <div :class="item.show ? 'mask text2-box' : 'mask1 text2-box'" v-if="item.customizeId">
              <img :src="item.images.item1.src">
              <div class="text2">{{ item.description.text0 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="box2">
      <div class="box2-title">官方社群</div>
      <div class="box">
        <div class="" v-for="(item, index) in association" :key="index">
          <div class="swiper1-box"  @mouseenter="handleMouseEnter(1, index)" @mouseleave="handleMouseLeave(1, index)">
            <div class="swiper1-item">
              <img :src="item.images.item0.src">
              <div class="text0">{{ item.description.text0 }}</div>
              <div class="text1" :style="item.show ? 'font-size:0' : ''">{{ item.description.text2 }}</div>
            </div>
            <div :class="item.show ? 'mask text2-box' : 'mask1 text2-box'">
              <img :src="item.images.item1.src">
              <div class="text2">{{ item.description.text0 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="box2">
      <div class="box2-title">官方社群</div>
      <Swiper class="box swiper1" :options="options" ref="swiper1" style="margin-left: 36px;margin-right: 36px;">
        <SwiperSlide v-for="(item, index) in association" :key="item.description.text0 + index">
          <div class="swiper1-box" @mouseenter="handleMouseEnter(1, index)" @mouseleave="handleMouseLeave(1, index)">
            <div class="swiper1-item">
              <img :src="item.images.item0.src">
              <div class="text0">{{ item.description.text0 }}</div>
              <div class="text1" :style="item.show ? 'font-size:0' : ''">{{ item.description.text2 }}</div>
            </div>
            <div :class="item.show ? 'mask text2-box' : 'mask1 text2-box'">
              <img :src="item.images.item1.src">
              <div class="text2">{{ item.description.text0 }}</div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
    <div class="box2" style="margin-bottom: 40px;">
      <div class="box2-title">跨境资料</div>
      <Swiper class="box swiper2" :options="options1" ref="swiper2" style="margin-left: 36px;margin-right: 36px;">
        <SwiperSlide v-for="(item, index) in association1" :key="item.description.text0 + index">
          <div class="swiper1-box" @mouseenter="handleMouseEnter(2, index)" @mouseleave="handleMouseLeave(2, index)">
            <div class="swiper1-item">
              <img :src="item.images.item0.src">
              <div class="text0">{{ item.description.text0 }}</div>
              <div class="text1" :style="item.show ? 'font-size:0' : ''">{{ item.description.text2 }}</div>
            </div>
            <div :class="item.show ? 'mask text2-box' : 'mask1 text2-box'">
              <img :src="item.images.item1.src">
              <div class="text2">{{ item.description.text0 }}</div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div> -->
    <div style="height: 88px;"></div>
  </div>
</template>

<script>
import { homebanners, homemune, customize } from "@/api/index";

export default {
  name: 'customer',
  components: {

  },
  data() {
    return {
      nextTime: '',
      nextTransForm1: '',
      nextTransForm2: '',
      cousmsList: [],
      interval: 3000,
      banners: [],
      munelist: [],
      active: '0',
      activeName: 'second',
      association: [],
      association1: [],
      options: {
        //导航前进后退按钮
        //自动轮播图
        autoplay: {
          delay: 0,
          disableOnInteraction: true,
        },
        speed: 5000,
        spaceBetween: 30,
        slidesPerView: 4,
        //无缝衔接
        // loop: true,
        //分页器配置项
      },
      options1: {
        //导航前进后退按钮
        //自动轮播图
        autoplay: {
          delay: 0,
          reverseDirection: true,
          disableOnInteraction: true,
          freeMode: true
        },
        speed: 5000,
        freeMode: true,
        spaceBetween: 30,
        slidesPerView: 4,
        //无缝衔接
        // loop: true,
        //分页器配置项
      },
      Swiper1: '',
      Swiper2: '',
    }
  },
  computed: {

  },
  created() {
    // console.log('2222222222222')
    this.getList();
    this.getMune();
    this.getData('官方社群');
    this.getData('跨境资料');
  },
  methods: {
    // this.mySwiper.autoplay.stop()
    handleMouseEnter(num, index) {
      // console.log("移入", this.$refs.swiper1.$swiper)
      // this.nextTransForm1 = this.$refs.swiper1.$swiper.$wrapperEl[0].style.transform;
      // // 轮播图原本应移动到的位置
      // let nextTransPosition = -1 * parseInt(this.nextTransForm1.split("px")[0].split("translate3d(")[1]);
      // // // 鼠标悬浮时时轮播图位置
      // let nowTransPosition = -1 * parseInt(window.getComputedStyle(this.$refs.swiper1.$swiper.$wrapperEl[0], false)["transform"].split("1, ")[2].split(",")[0]);
      // // 存放鼠标悬浮时轮播图的真实transform属性（非行内属性）
      // let nowTransForm = window.getComputedStyle(this.$refs.swiper1.$swiper.$wrapperEl[0], false)["transform"];
      // // 计算轮播图从暂停位置移动到原本应到的位置所用的时间（150是我自定义的每个slide的宽度）
      // this.nextTime = 4000 * ((nextTransPosition - nowTransPosition) / 150);
      // // 改变行内transform属性
      // this.$refs.swiper1.$swiper.$wrapperEl[0].style.transform = nowTransForm;
      // this.$refs.swiper1.$swiper.$wrapperEl[0].style.transitionDuration = "0ms";
      // return
      if (num == 1) {
        // this.$refs.swiper1.$swiper.autoplay.stop()
        this.association[index].show = true
      } else {
        // this.$refs.swiper2.$swiper.autoplay.stop()
        this.association1[index].show = true
      }
    },
    handleMouseLeave(num, index) {
      if (num == 1) {
        // this.$refs.swiper1.$swiper.autoplay.stop()
        // this.$refs.swiper1.$swiper.autoplay.start()
        this.association[index].show = false
      } else {
        // this.$refs.swiper2.$swiper.autoplay.stop()
        // this.$refs.swiper2.$swiper.autoplay.start()
        this.association1[index].show = false
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    activeAction(active) {
      this.active = active;
    },
    getList() {
      var data = {
        type: 'contact'
      }
      homebanners(data).then(res => {
        // console.log('联系我们', res.data['contact']['context']);
        this.banners = JSON.parse(res.data['contact']['context']);
        // console.log("this.banners", this.banners)
      });
    },
    getData(type = '官方社群') {
      // console.log("官方社群")
      var data = {
        type
      }
      customize(data).then(res => {
        if (res.code == 200) {
          res.data.forEach(element => {
            element.show = false
          });
          if (type == '官方社群') {
            // if (res.data.length % 4 == 1) {
            //   res.data.push({
            //     images:{
            //       item0:{
            //         src:''
            //       },
            //       item1:{
            //         src:''
            //       }
            //     },
            //     description:{},
            //     show:false
            //   })
            //   res.data.push({
            //     images:{
            //       item0:{
            //         src:''
            //       },
            //       item1:{
            //         src:''
            //       }
            //     },
            //     description:{},
            //     show:false
            //   })
            //   res.data.push({
            //     images:{
            //       item0:{
            //         src:''
            //       },
            //       item1:{
            //         src:''
            //       }
            //     },
            //     description:{},
            //     show:false
            //   })
            // }
            // if (res.data.length % 4 == 2) {
            //   res.data.push({
            //     images:{
            //       item0:{
            //         src:''
            //       },
            //       item1:{
            //         src:''
            //       }
            //     },
            //     description:{},
            //     show:false
            //   })
            //   res.data.push({
            //     images:{
            //       item0:{
            //         src:''
            //       },
            //       item1:{
            //         src:''
            //       }
            //     },
            //     description:{},
            //     show:false
            //   })
            // }
            // if (res.data.length % 4 == 3) {
            //   res.data.push({
            //     images:{
            //       item0:{
            //         src:''
            //       },
            //       item1:{
            //         src:''
            //       }
            //     },
            //     description:{},
            //     show:false
            //   })
            // }
            this.association = res.data
            console.log("官方社群this.association", this.association)
          } else if (type == '跨境资料') {
            // if (res.data.length % 4 == 1) {
            //     res.data.push({
            //       images:{
            //         item0:{
            //           src:''
            //         },
            //         item1:{
            //           src:''
            //         }
            //       },
            //       description:{},
            //       show:false
            //     })
            //     res.data.push({
            //       images:{
            //         item0:{
            //           src:''
            //         },
            //         item1:{
            //           src:''
            //         }
            //       },
            //       description:{},
            //       show:false
            //     })
            //     res.data.push({
            //       images:{
            //         item0:{
            //           src:''
            //         },
            //         item1:{
            //           src:''
            //         }
            //       },
            //       description:{},
            //       show:false
            //     })
            //   }
            //   if (res.data.length % 4 == 2) {
            //     res.data.push({
            //       images:{
            //         item0:{
            //           src:''
            //         },
            //         item1:{
            //           src:''
            //         }
            //       },
            //       description:{},
            //       show:false
            //     })
            //     res.data.push({
            //       images:{
            //         item0:{
            //           src:''
            //         },
            //         item1:{
            //           src:''
            //         }
            //       },
            //       description:{},
            //       show:false
            //     })
            //   }
            //   if (res.data.length % 4 == 3) {
            //     res.data.push({
            //       images:{
            //         item0:{
            //           src:''
            //         },
            //         item1:{
            //           src:''
            //         }
            //       },
            //       description:{},
            //       show:false
            //     })
            //   }
            this.association1 = res.data
          }
        }

      });
    },
    getMune() {
      homemune().then(res => {
        var munelist = ''
        res.data.forEach(item => {
          if (item.menuName == '关于我们') {
            // 盒子每一行不足3个补满3个
            // item.children.forEach(it => {
            //   if (it.labelsList.length % 4 == 1) {
            //     it.labelsList.push({
            //       labelIcon: '',
            //       describes: "",
            //     })
            //     it.labelsList.push({
            //       labelIcon: '',
            //       describes: "",
            //     })
            //     it.labelsList.push({
            //       labelIcon: '',
            //       describes: "",
            //     })
            //   }
            // })
            // item.children.forEach(it => {
            //   if (it.labelsList.length % 4 == 2) {
            //     it.labelsList.push({
            //       labelIcon: '',
            //       describes: "",
            //     })
            //     it.labelsList.push({
            //       labelIcon: '',
            //       describes: "",
            //     })
            //   }

            // })

            munelist = item.children;
          }
        });
        this.munelist = munelist;
        // console.log('munelist', munelist)
      });
    },
  },
  mounted() {


  }
}
</script>

<style scoped>
@import url('./customer.css');

.index_block_4 {
  box-shadow: 0px 0px 9px 0px rgba(33, 119, 184, 0.1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  height: 560px;
  width: 100%;
}

.index_group_20 {
  height: 560px;
  width: 100%;
  /* aspect-ratio: 164/56; */
}

.index_group_20 .el-carousel {
  position: relative;
  width: 100%;
  height: 100%;
}

.index_group_20 .el-carousel .el-carousel__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* padding-top: 34.14%;  */
  background: #f0f0f0;
}

.index_group_20 .el-carousel img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.index_group_20 .el-carousel--horizontal {
  overflow: visible;
}

.index_group_20 .el-carousel__container {
  height: 100%;
  overflow: hidden;
}

.box {
  background: #fff;
  cursor: pointer;
  margin-top: 33px;
  /* min-width: 1284px; */
}

.box1 {
  background: #fff;
  cursor: pointer;
  margin-top: 33px;
  /* min-width: 1284px; */
}

.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  padding-top: 24px;

}

.tab-tabs {
  display: flex;
  justify-content: center;
  padding: 24px 0 0px;
}

.tab-item-box {

  display: flex;
}

.tab-item {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  margin-right: 60px;
}

.uk-active {
  color: #21ac34;
}

.ele-wrap {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: flex-start; */
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 340px);
  grid-gap: 10px;
  padding: 0 70px;
}

.ele {
  width: 346px;
  /* margin-top: 45px; */
  /* margin-left: 20px; */
}

.ele-item {
  overflow: hidden;
  width: 346px;
  /* height: 245px; */
}

.ele-font {
  margin-top: 19px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 34px;
}

.ele-item img {

  transition: all 0.3s ease-in;
  width: 346px;
  height: 209px;
}

.ele-item img:hover {
  transform: scale(1.2);
}

/deep/ .el-tabs__nav-wrap {
  padding-top: 24px;
  font-size: 16px;
}

/deep/ .el-tabs__item {
  color: #333333;
  font-size: 24px;
  font-weight: 500;
}

/deep/ .el-tabs__item.is-active {
  color: #21ac34;
}

/deep/.el-tabs__nav-wrap::after {
  border: none;
  height: 0;
}

/deep/ .el-tabs__active-bar {
  height: 0;
}

/deep/ .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}

.box2 {
  background: #FFF;

  margin: 24px 0;
  /* padding-bottom: 24px; */
}

.box2-title {
  padding-top: 24px;
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.swiper1-item {
  height: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.swiper1-box {
  position: relative;
  width: 320px;
  height: 210px;
  border-radius: 4px;
  border: 1px solid #E9E9E9;
  background: #FFF;
  overflow: hidden;
  margin-bottom: 34px;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 320px;
  height: 210px;
  background: rgba(0, 0, 0, 0.5);
}

.swiper1-item>img {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  margin: 24px 0 16px;
}

.text0 {

  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 116.667% */
}

.text1 {
  width: 320px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(33, 172, 52, .07);
  position: absolute;
  left: 0;
  bottom: 0;
  color: #21AC34;


  font-size: 24px;
  font-style: normal;
  font-weight: 400;



}

.mask1 {
  display: none;
}

.text2-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text2-box>img {
  width: 120px;
  height: 120px;
  margin: 24px 0 17px;
}

.text2-box>div {
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  display: flex;
  justify-content: center;
}

.box>/deep/ .swiper-wrapper {


  /* 默认是ease-out */

  -webkit-transition-timing-function: linear !important;

  -ms-transition-timing-function: linear !important;

  -moz-transition-timing-function: linear !important;

  -o-transition-timing-function: linear !important;

  transition-timing-function: linear !important;

}

.swiper1 {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; */
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 320px);
  grid-gap: 10px;
  /* min-width: 1460px; */
}
</style>